/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PromoCodeService from '../services/PromoCodeService';

export const createPromocode = createAsyncThunk(
  'promoCodes/createPromocode',
  async ({
    promoCode,
    promocodeDescription,
    discountType,
    maxTotalUsage,
    maxFrequency,
    discountAmount,
    discountPercentage,
    startDate,
    endDate,
    operators,
    locations,
    promocodeId
  }) => {
    try {
      const response = await PromoCodeService.createPromocode({
        promoCode,
        promocodeDescription,
        discountType,
        maxTotalUsage,
        maxFrequency,
        discountAmount,
        discountPercentage,
        startDate,
        endDate,
        operators,
        locations,
        promocodeId
      });
      if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
        const data = await response?.data;
        if (typeof promocodeId !== 'undefined') {
          data['promocodeId'] = promocodeId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deletePromocode = createAsyncThunk(
  'promoCodes/deletePromocode',
  async (promocode) => {
    const { id: promocodeId, name: code } = promocode;
    const response = await PromoCodeService.deletePromocode(code);
    const data = response.data;
    data['promocodeId'] = promocodeId;
    return data;
  }
)

export const getPromoCode = createAsyncThunk(
  'promoCodes/getPromoCode',
  async (code) => {
    const response = await PromoCodeService.getPromoCode(code);
    const data = response.data;
    return data;
  }
);

export const getPromoCodes = createAsyncThunk(
  'promoCodes/getPromoCodes',
  async (code) => {
    const response = await PromoCodeService.getPromoCodes(code);
    const data = response.data;
    return data;
  }
);

export const getPromoCodeValidity = createAsyncThunk(
  'promoCodes/getPromoCodeValidity',
  async ({ promoCode, locationId, allowPromocode }) => {
    try {
      const response = await PromoCodeService.getPromoCodeValidity({ promoCode, locationId, allowPromocode });
      const data = response.data;
      return data;
    }
    catch (error) {
      throw Error(error.response.data.message)
    }
  }
);

export const getPassCodeValidity = createAsyncThunk(
  'promoCodes/getPassCodeValidity',
  async ({ passCode, rate_id }) => {
    try {
      const response = await PromoCodeService.getPassCodeValidity({ passCode, rate_id });
      const data = response.data;
      return data;
    }
    catch (error) {
      throw Error(error.response.data.message)
    }
  }
);

export const promoCodesSlice = createSlice({
  name: 'promocodes',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createPromocode.pending]: (state) => {
      state.loading = true;
    },
    [createPromocode.rejected]: (state, action) => {
      state.loading = false;
    },
    [createPromocode.fulfilled]: (state, action) => {
      if (typeof action.payload.promocodeId !== 'undefined') {
        state.loading = false;
        let promocodes = state.data.filter((promocode) => promocode.id !== action.payload.promocodeId);
        promocodes.push(action.payload);
        state.data = promocodes;
      } else if (typeof action.payload.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload);
      }
    },
    [deletePromocode.pending]: (state) => {
      state.loading = true;
    },
    [deletePromocode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deletePromocode.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state.data.filter((promoCode) => promoCode.id !== action.payload.promocodeId);
    },
    [getPromoCode.pending]: (state) => {
      state.loading = true;
    },
    [getPromoCode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPromoCode.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [getPromoCodeValidity.pending]: (state) => {
      state.loading = true;
    },
    [getPromoCodeValidity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPromoCodeValidity.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [getPassCodeValidity.pending]: (state) => {
      state.loading = true;
    },
    [getPassCodeValidity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPassCodeValidity.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [getPromoCodes.pending]: (state) => {
      state.loading = true;
    },
    [getPromoCodes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPromoCodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default promoCodesSlice.reducer;
