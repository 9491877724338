/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ParkingSessionService from '../services/ParkingSessionService';

export const createParkingSession = createAsyncThunk(
  'parkingsession/createParkingSession',
  async (payload) => {
    try {
      const response = await ParkingSessionService.createParkingSession(payload);

      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      return data;
    } catch (e) {
      return e?.response?.data || { message: "Parking session failed to be created" }
      // throw new Error(e || "Parking session failed to be created");
    }
  }
);

export const extendParkingSession = createAsyncThunk(
  'parkingsession/extendParkingSession',
  async (parkingSession) => {
    const response = await ParkingSessionService.extendParkingSession(parkingSession);
    const data = response.data;
    return data;
  }
);

export const sendParkingSesssionPaymentReceipt = createAsyncThunk(
  'parkingsession/sendParkingSesssionPaymentReceipt',
  async ({ parkingSessionSlug, email, parkingSessionStartDatetime, parkingSessionEndDatetime, paymentDate, timezoneOffset }) => {
    const response = await ParkingSessionService.sendParkingSesssionPaymentReceipt({
      parkingSessionSlug,
      email,
      parkingSessionStartDatetime,
      parkingSessionEndDatetime,
      paymentDate,
      timezoneOffset
    });
    const data = response.data;
    return data;
  }
);

export const sendFlexparkPaymentReceipt = createAsyncThunk(
  'parkingsession/sendFlexparkPaymentReceipt',
  async ({ flexpark_id, email, startDatetime, endDatetime, paymentDate, timezoneOffset }) => {
    const response = await ParkingSessionService.sendFlexparkPaymentReceipt({
      flexpark_id,
      email,
      startDatetime,
      endDatetime,
      paymentDate,
      timezoneOffset
    });
    const data = response.data;
    return data;
  }
);

export const checkValidParkingSession = createAsyncThunk(
  'parkingsession/checkValidParkingSession',
  async ({ locationSlug, parkingSessionSlug }) => {
    try {
      const response = await ParkingSessionService.checkValidParkingSession({
        locationSlug, parkingSessionSlug
      });
      const data = response.data;
      return data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);


export const getLicensePlateNumber = createAsyncThunk(
  'parkingsession/getLicensePlateNumber',
  async (operator_id) => {
    try {
      const response = await ParkingSessionService.getLicensePlateNumber(operator_id);
      return response.data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);

export const redeemFlexPass = createAsyncThunk(
  'parkingsession/redeemFlexPass',
  async (parkingSession) => {
    const response = await ParkingSessionService.redeemFlexPass(parkingSession);
    const data = response.data;
    return data;
  }
);

export const purchaseFlexPass = createAsyncThunk(
  "parkingsession/purchaseFlexPass",
  async (payload) => {
    const response = await ParkingSessionService.purchaseFlexPass(payload);
    const data = response.data;
    return data;
  }
);

export const purchaseMonthlyPass = createAsyncThunk(
  "parkingsession/purchaseMonthlyPass",
  async (payload) => {
    const response = await ParkingSessionService.purchaseMonthlyPass(payload);
    const data = response.data;
    return data;
  }
);

export const parkingSessionSlice = createSlice({
  name: 'parkingsession',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createParkingSession.pending]: (state) => {
      state.parkingSessionLoading = true;
    },
    [createParkingSession.rejected]: (state, action) => {
      state.parkingSessionLoading = false;
      state.error = action.error.message;
    },
    [createParkingSession.fulfilled]: (state, action) => {
      state.parkingSessionLoading = false;
      state.data = action.payload;
    },
    [sendParkingSesssionPaymentReceipt.pending]: (state) => {
      state.sendReceiptEmailLoading = true;
    },
    [sendParkingSesssionPaymentReceipt.rejected]: (state, action) => {
      state.sendReceiptEmailLoading = false;
      state.error = action.error.message;
    },
    [sendParkingSesssionPaymentReceipt.fulfilled]: (state, action) => {
      state.sendReceiptEmailLoading = false;
      state.sendReceiptEmailData = action.payload;
    },
    [sendFlexparkPaymentReceipt.pending]: (state) => {
      state.sendReceiptEmailLoading = true;
    },
    [sendFlexparkPaymentReceipt.rejected]: (state, action) => {
      state.sendReceiptEmailLoading = false;
      state.error = action.error.message;
    },
    [sendFlexparkPaymentReceipt.fulfilled]: (state, action) => {
      state.sendReceiptEmailLoading = false;
      state.sendReceiptEmailData = action.payload;
    },
    [checkValidParkingSession.pending]: (state) => {
      state.loading = true;
    },
    [checkValidParkingSession.rejected]: (state, action) => {
      state.loading = false;
      state.validParkingSessionError = action.error.message;
    },
    [checkValidParkingSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.parking_session;
      state.validSession = action.payload.valid_time_window
      state.rate = action.payload.rate;
      state.ticketPrintScan = action.payload.ticket_print_scan;
    },
    [extendParkingSession.pending]: (state) => {
      state.loading = true;
    },
    [extendParkingSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [extendParkingSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getLicensePlateNumber.pending]: (state) => {
      state.loading = true;
    },
    [getLicensePlateNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLicensePlateNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [redeemFlexPass.pending]: (state) => {
      state.loading = true;
    },
    [redeemFlexPass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [redeemFlexPass.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [purchaseFlexPass.pending]: (state) => {
      state.purchaseFlexPassLoading = true;
    },
    [purchaseFlexPass.rejected]: (state, action) => {
      state.purchaseFlexPassLoading = false;
      state.error = action.error.message;
    },
    [purchaseFlexPass.fulfilled]: (state, action) => {
      state.purchaseMonthlyPassLoading = false;
      state.data = action.payload;
    },
    [purchaseMonthlyPass.pending]: (state) => {
      state.purchaseMonthlyPassLoading = true;
    },
    [purchaseMonthlyPass.rejected]: (state, action) => {
      state.purchaseMonthlyPassLoading = false;
      state.error = action.error.message;
    },
    [purchaseMonthlyPass.fulfilled]: (state, action) => {
      state.purchaseMonthlyPassLoading = false;
      state.data = action.payload;
    },
  },
});

export default parkingSessionSlice.reducer;
